<template>
  <v-container>
    <v-alert
      v-show="error"
      transition="fade-transition"
      color="error"
      type="error"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
    >
      {{ errorMessage }}
    </v-alert>

    <v-row justify="center">
      <v-col xl="9">
        <h2>All Properties List</h2>

        <p class="mb-10 grey--text">
          A comprehensive list of available properties for sale or rent in a
          specific area. This list might include details such as property type,
          location, size, amenities, and price.
        </p>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          class="mb-5"
          single-line
          hide-details
          clearable
        />

        <v-data-table
          :headers="property_header"
          :items="filtered_properties"
          :loading="loading"
          loading-text="Please wait..."
          :no-data-text="error ? errorMessage : 'No property found.'"
          :no-results-text="error ? errorMessage : 'No property found.'"
          :items-per-page="-1"
          @click:row="handle_click_property"
          item-key="id"
        >
          <template v-slot:item.customers="{ item }">
            <li v-for="(customer, index) in item.customers" :key="index">
              {{ customer.first_name }} {{ customer.last_name }}
            </li>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import filt from "@/plugins/filters";

export default {
  data() {
    return {
      loading: true,
      error: null,
      errorMessage: "",
      search: "",

      property_header: [
        {
          text: "Address",
          value: "address",
        },

        {
          text: "Owned By",
          value: "customers",
        },
      ],

      properties: [],
    };
  },

  methods: {
    handle_click_property(value) {
      this.$router.push(`/dashboard/admin/read-property/${value.id}`);
    },

    async getData() {
      try {
        this.loading = true;

        // Get all property
        const response = await API().get(
          `api/internal-admin/property/read_all_property`
        );

        this.properties = response.data; // Set property to response data

        // console.log(this.properties);

        this.loading = false;
      } catch (error) {
        this.error = true;
        console.log(error);
        this.errorMessage = error;
        this.loading = false;

        setTimeout(() => {
          this.error = false;
          this.errorMessage = "";
        }, 5000);
      }
    },
  },

  computed: {
    filtered_properties() {
      const result = [];

      for (const property of this.properties) {
        if (property.address) {
          const street_number = this.$options.filters.capitalize(
            String(property.address.street_number)
          );

          const street_name = this.$options.filters.capitalize_each_word(
            String(property.address.street_name)
          );

          const street_type = this.$options.filters.capitalize_each_word(
            String(property.address.street_type)
          );

          const street_direction = this.$options.filters.capitalize(
            String(property.address.street_direction)
          );

          const unit = this.$options.filters.capitalize_each_word(
            String(property.address.unit)
          );

          const postal_code = this.$options.filters.capitalize(
            String(property.address.postal_code)
          );

          const city = this.$options.filters.capitalize_each_word(
            String(property.address.city)
          );
          const province = this.$options.filters.capitalize_each_word(
            String(property.address.province)
          );

          result.push({
            id: property.id,
            address: `${street_number ? street_number : ""} ${
              street_name ? ", " + street_name : ""
            } ${street_type ? ", " + street_type : ""} ${
              street_direction ? ", " + street_direction : ""
            } ${unit ? ", " + unit : ""} ${
              postal_code ? ", " + postal_code : ""
            } ${city ? ", " + city : ""} ${province ? ", " + province : ""}`,
            customers: property.customers,
          });
        }
      }

      if (this.search !== "") {
        return result.filter((item) => {
          const addressMatched = item.address
            .toLowerCase()
            .includes(this.search.toLowerCase());

          const customersMatched = item.customers.some((customer) => {
            return (
              customer.first_name
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              customer.last_name
                .toLowerCase()
                .includes(this.search.toLowerCase())
            );
          });

          return addressMatched || customersMatched;
        });
      } else {
        return result;
      }
    },
  },

  filters: {
    ...filt,
  },

  created() {
    this.getData();
  },
};
</script>
